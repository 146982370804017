.grid-2-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.grid-3-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
}

.grid-4-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1rem;
    grid-row-gap: 1.5rem;
}

.grid-5-col {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
}

.grid-infinite-col {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    grid-template-rows: auto;
    grid-gap: 1.5rem;
}

.grid-tech-list {
    display: grid;
    grid-template-columns: 100px 1fr 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 0rem;
    grid-row-gap: 1rem;
    border-bottom: 1px solid #eaeaea;
}

.grid-tech-stat {
    display: grid;
    grid-template-columns: 100px 1fr 120px;
    grid-auto-rows: auto;
    grid-column-gap: 0.5rem;
    grid-row-gap: 1.5rem;
}

.grid-tech-stat > *, .grid-tech-list > * {
    padding: 0.25rem 1.5rem;
}

.grid-tech-list:last-child {
    border-bottom: none;
}

.tk-grid {
    display: grid;
    position: relative;
    grid-template-columns: 300px 1fr;
}


.grid-calltakers {
    grid-template-columns: 1fr 1fr;
}

.no-gap {
    grid-column-gap: 0rem;
    grid-row-gap: 0rem;
}

.md-gap {
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
}

.lg-gap {
    grid-column-gap: 6rem;
    grid-row-gap: 6rem;
}

.col-span-2 {
    grid-column: 2 / 4;
}


@media screen and (max-width: 1200px) {
    .grid-4-col {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-5-col {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    .grid-infinite-col {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .grid-2-col {
        grid-template-columns: auto;
    }
    .grid-3-col {
        grid-template-columns: 1fr 1fr;
    }
    .grid-4-col {
        grid-template-columns: auto;
    }
    .grid-5-col {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .grid-infinite-col {
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
    }
}


@media screen and (max-width: 767px) {
    .col-span-2 {
        grid-column: auto;
    }

    .grid-2-col {
        grid-template-columns: 1fr;
    }
    .grid-3-col {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 576px) {

    .grid-5-col {
        grid-template-columns: 1fr 1fr;
    }
}