:root {
  --shadow-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1),0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --text-muted: #6B7280;
  --bg-light: #F3F4F6;
  --bg-extra-light: #F9FAFB;
  --rounded: 0.375rem;
  --border-color: rgb(209 213 219);
}

body {
  margin: 0;
  background-color: var(--bg-light);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table.payment-request-table td, table.payment-request-table th {
  padding-right: 15px;
  vertical-align: middle;
}

.settings-section {
  background-color: #fff;
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  padding: 1.25rem;
  box-shadow: var(--shadow-sm);
}

.card {
  background-color: #fff;
  border-radius: 0.375rem;
  margin-bottom: 1.25rem;
  border: none;
  box-shadow: var(--shadow-sm);
}

.card-body {
  padding: 1.35rem;
}

.hidden-anchor {
  position: relative;
  top: -100px;
}

.color-picker:hover {
  cursor: pointer;
}

.color-picker:focus {
  cursor: default;
}

.bg-dark {
  background-color: #132144 !important;
}

.nav-link {
  color: #6c6c7f !important;
  font-size: 15px !important;
  border-radius: 6px;
  transition: all 0.3s;
  font-weight: 600 !important;
}

.sub-nav-link-container {
  margin-left: 1.2rem;
  padding-left: 1rem;
  border-left: 2px solid hsla(240, 6%, 87%, 1);
}

.sub-nav-link {
  color: #6c6c7f !important;
  padding: none !important;
  font-size: 13px !important;
  line-height: 1.2rem;
  padding: 0.5rem !important;
  font-weight: 600;
}

.navbar {
  padding: 0 !important;
}

.page-title {
  margin-bottom: 4.5rem;
}

.sidebar-active, .sub-nav-link:hover {
  color: var(--dark) !important;
  background-color: hsla(240, 8%, 46%, 0.15);
}

.sub-nav-link.nav-link > svg {
  font-size: 0.3rem !important;
  margin-right: 0.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customLoginContainer {
  height: 100%;
}

.loginLogoImageContainer {
  width:100%;
  text-align:center;
  height:100px;
  margin-bottom:60px;
}

.loginLogoImage {
  position:relative;
  width:75px;
  margin-bottom: 25px;
}

.loginBtn {
  margin-top:15px;
  width:100%;
}

.loginLoadingSpinner {
  padding-top:15px;
  width:100%;
  text-align:center;
}

.sidebar-collapse {
	display: block;
	background-color: rgb(230, 230, 230);
}

.sidebar-collapse-link {
	padding-left: 48px !important;
}

.myRwNavText {
  position: relative;
  top: 1px;
  color: #FFF !important;
}

.myRwiFrame {
  border: 0;
  width: 100%;
  height: 1000px;
}

.myRwHamburger {
  border-color: #FFF !important;
}

.myRwHamburger:hover {
  background-color: #429dff !important;
}

.myRwHamburger span {
  color: #FFF !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
  height: 20px;
  width: 20px;
}

.merchanttrack-button {
  background: none;
  border: none;
  color: var(--primary);
  font-weight: bold;
}

.tech-profile {
  background-color: var(--bg-light);
  width: 32px;
  height: 32px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 13px;
}

.grid-container {
  position: relative;
}

.page-header {
  margin-bottom: 2rem;
}

@media (max-width: 768px){
  .sidebar {
    margin-bottom: 1rem !important;
  }

  .nav-link > svg {
    margin-right: 1rem;
  }
}


@media (min-width: 768px) { /* everything NOT in mobile mode */
	/* body {
		padding: 80px 2px 0 225px;
  } */

  .grid-container {
    position: relative;
    display: grid;
    grid-column-gap: 0px;
    grid-row-gap: 32px;
    grid-template-columns: 224px auto;
  }

  .grid-full-width {
    grid-column: 1/3
  }

  .height-wrapper {
    position: relative;
  }

  .website-wrapper {
    height: 100vh;
    overflow: scroll;
  }

  .top-nav {
    padding: 15px;
    overflow: hidden;
    position: sticky;
    align-self: start;
    top: 0px;
    background-color: #fff;
    left: 0;
    right: 0;
    box-shadow: var(--shadow-sm);
    text-align: right;
    z-index: 1;
  }

  .sidebar {
    grid-column-start: 1;
    position: fixed;
    align-self: start;
    top: 0px;
    background-color: var(--light);
    border-right: 1px solid #dfe3e8;;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 2;
  }

  .nav-link > svg {
    position: relative;
    font-size: 1.25rem !important;
    margin-right: 1rem;
    top: -1px;
  }

  .grid-body {
    grid-column-start: 2;
  }
}

.navLogoSvg {
  position: relative;
  top: -2px;
  height: 25px;
  margin-right: 10px;
}

.myRwCardHeader {
  background-color: #007bff !important;
  color: #FFF !important;
}

.myRwH3 {
  color: #6e6e6e;
  margin-bottom: 20px;
}

.myRwLoadingDiv {
  background-color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
}

.myRwCallLogLoadingDiv {
  height: 761px;
}

.myRwCyberColorSelectBox {
  border: 1px solid #ced4d9;
  background-color: #FFF;
  border-radius: 5px;
  cursor: pointer;
}

.myRwCyberColorSelectBox:hover {
  border: 1px solid #80b6f0;
}

.myRwCyberColorSelectSwatch {
  position: relative;
  top: 2px;
  float: left;
  height: 20px;
  width: 20px;
  margin-right: 2px;
  border-radius: 5px;
}
  .defaultSwatch {
    background-color: #007bff;
  }
  .slateSwatch {
    background-color: #6e6e6e;
  }
  .crimsonSwatch {
    background-color: #d12228;
  }
  .mintSwatch {
    background-color: #58bf90;
  }

.divider {
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

.whiteContainer {
  margin-top: 0px;
  margin-bottom: 15px;
  padding: 15px;
  background-color: #fff;
  box-shadow: 7px 7px 15px 5px rgba(60,66,87,.12), 0 3px 6px 0 rgba(0,0,0,.12);
  border-radius: 0 0 5px 5px;
}

.loggedInStatus {
  position: relative;
  top: 2px;
}

.myRwLi {
  margin: 1em 0;
}

.PVRDiv {
  border: 1px solid #ced4da;
  padding: 5px;
  margin-top: 10px;
}

.PVRItemHeader {
  display: inline-block;
  margin-top: 15px;
  font-weight: bold;
}

.PVRItemHeaderSeparator {
  height: 0;
  width: 100%;
  border-top: 1px dashed #ced4da;
}
.boldMyRW {
  font-weight: bold;
}

.myRWPagination {
  position: relative;
  text-align: center;
  float: right;
}

.myRWCallRecord {
  margin-bottom: 15px;
}
.myRWCallRecordHeader {
  background-color: #e6eeee;
}
.myRWCallRecordCallID {
  color: #0185cd;
}
.quickPicImageContainer {
  border: 1px dashed #e3e8ee;
  padding: 5px;
}
.quickPicImageContainerInner {
  margin-top: 5px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.quickPicImage {
  max-width: 100%;
}

.rss-server-status {
  position: relative;
  top: 3px;
  left: 2px;
  color: #FFF;
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
  border: 1px solid transparent;
}

.RSSServerStatusRunning { color: #28a745; border-color: #28a745 }
.RSSServerStatusStopped { color: #dc3545; border-color: #dc3545 }
.RSSServerStatusStopping { color: #ffc107; border-color: #ffc107 }
.RSSServerStatusPending { color: #ffc107; border-color: #ffc107 }
.RSSServerStatusUnknown { color: #6e6e6e; border-color: #6e6e6e }

.myRWTextArea {
  font-size: small !important;
}
.myRWRoboCallingRecognizedVariables {
  margin-top: 10px;
}
.myRWRoboCallingRecognizedVariables ul {
  list-style-type: none;
  padding: 0;
}
.myRWClickableRoboTag {
  cursor: pointer;
  color: #0185cd;
}
.myRWClickableRoboTag:hover {
  color: #28a745;
}

.myRWReviewLinkText {
  width: 150px;
}

.stat-border {
  border-left: 2px solid white;
  border-right: 2px solid white;
}

.stat-hover:hover {
  opacity: 0.75;
}

.website-preview {
  border: none;
}

.link {
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.underline {
  text-decoration: underline;
}

.ui.placeholder{
  position:static;
  overflow:hidden;
  -webkit-animation:placeholderShimmer 2s linear;
  animation:placeholderShimmer 2s linear;
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite;
  background-color:#fff;
  background-image:-webkit-gradient(linear,left top,right top,from(rgba(0,0,0,.08)),color-stop(15%,rgba(0,0,0,.15)),color-stop(30%,rgba(0,0,0,.08)));
  background-image:-webkit-linear-gradient(left,rgba(0,0,0,.08) 0,rgba(0,0,0,.15) 15%,rgba(0,0,0,.08) 30%);
  background-image:linear-gradient(to right,rgba(0,0,0,.08) 0,rgba(0,0,0,.15) 15%,rgba(0,0,0,.08) 30%);background-size:1200px 100%;max-width:30rem
}

@-webkit-keyframes placeholderShimmer{
  0%{background-position:-1200px 0}100%{background-position:1200px 0}
}

@keyframes placeholderShimmer{
  0%{background-position:-1200px 0}100%{background-position:1200px 0}
}

.ui.placeholder+.ui.placeholder{
  margin-top:2rem
}
.ui.placeholder+.ui.placeholder{
  -webkit-animation-delay:.15s;animation-delay:.15s
}

.ui.placeholder+.ui.placeholder+.ui.placeholder{
  -webkit-animation-delay:.3s;animation-delay:.3s
}
.ui.placeholder+.ui.placeholder+.ui.placeholder+.ui.placeholder{
  -webkit-animation-delay:.45s;animation-delay:.45s
}

.ui.placeholder+.ui.placeholder+.ui.placeholder+.ui.placeholder+.ui.placeholder{
  -webkit-animation-delay:.6s;animation-delay:.6s
}
.ui.placeholder .image:not(.header):not(.ui){
  height:100px
}
.ui.placeholder .square.image:not(.header){
  height:0;overflow:hidden;padding-top:100%
}
.ui.placeholder .rectangular.image:not(.header){
  height:0;overflow:hidden;padding-top:75%
}
.ui.placeholder .line{
  position:relative;height:.85714286em
}
.ui.placeholder .line:after,.ui.placeholder .line:before{
  top:100%;position:absolute;content:'';background-color:inherit
}
.ui.placeholder .line:before{
  left:0
}
.ui.placeholder .line:after{
  right:0
}
.ui.placeholder .line{
  margin-bottom:.5em
}
.ui.placeholder .line:after,.ui.placeholder .line:before{
  height:.5em
}
.ui.placeholder .line:not(:first-child){
  margin-top:.5em
}
.ui.placeholder .header{
  position:relative;
  overflow:hidden
}
.ui.placeholder .line:nth-child(1):after{
  width:0%
}
.ui.placeholder .line:nth-child(2):after{
  width:50%
}
.ui.placeholder .line:nth-child(3):after{
  width:10%
}
.ui.placeholder .line:nth-child(4):after{
  width:35%
}
.ui.placeholder .line:nth-child(5):after{
  width:65%
}
.ui.placeholder .header .line{
  margin-bottom:.64285714em
}
.ui.placeholder .header .line:after,.ui.placeholder .header .line:before{
  height:.64285714em
}
.ui.placeholder .header .line:not(:first-child){
  margin-top:.64285714em
}
.ui.placeholder .header .line:after{
  width:20%
}
.ui.placeholder .header .line:nth-child(2):after{
  width:60%
}
.ui.placeholder .image.header .line{
  margin-left:3em
}
.ui.placeholder .image.header .line:before{
  width:.71428571rem
}
.ui.placeholder .image.header:after{
  display:block;
  height:.85714286em;
  content:'';
  margin-left:3em
}
.ui.placeholder .header .line:first-child,.ui.placeholder .image .line:first-child,.ui.placeholder .paragraph .line:first-child{
  height:.01px
}
.ui.placeholder .header:not(:first-child):before,.ui.placeholder .image:not(:first-child):before,.ui.placeholder .paragraph:not(:first-child):before{
  height:1.42857143em;content:'';display:block
}
.ui.inverted.placeholder{
  background-image:-webkit-gradient(linear,left top,right top,from(rgba(255,255,255,.08)),color-stop(15%,rgba(255,255,255,.14)),color-stop(30%,rgba(255,255,255,.08)));
  background-image:-webkit-linear-gradient(left,rgba(255,255,255,.08) 0,rgba(255,255,255,.14) 15%,rgba(255,255,255,.08) 30%);
  background-image:linear-gradient(to right,rgba(255,255,255,.08) 0,rgba(255,255,255,.14) 15%,rgba(255,255,255,.08) 30%)
}
.ui.inverted.placeholder,.ui.inverted.placeholder .image.header:after,.ui.inverted.placeholder .line,.ui.inverted.placeholder .line:after,.ui.inverted.placeholder>:before{
  background-color:#1b1c1d
}
.ui.placeholder .full.line.line.line:after{
  width:0%
}
.ui.placeholder .very.long.line.line.line:after{
  width:10%
}
.ui.placeholder .long.line.line.line:after{
  width:35%
}
.ui.placeholder .medium.line.line.line:after{
  width:50%
}
.ui.placeholder .short.line.line.line:after{
  width:65%
}.ui.placeholder .very.short.line.line.line:after{
  width:80%
}
.ui.fluid.placeholder{
  max-width:none
}

.file-drop > .file-drop-target {
  width:100%;
  background-color: #f5f5f5;
  padding: 3rem 2rem;
  border-radius: 5px;
  border: 2px dashed #dfdfdf;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  background-color: #d8f1d9;
  border: 2px solid #bde2bf;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr;
}

.login-aside {
  background-image: url('assets/tile-background.svg');
  background-size: 500px;
  background-color: #F5F5F9;
  height: 100vh;
  overflow:hidden;
}

.login-aside-logo-wrapper {
  box-shadow: 0px 0px 300px 400px rgba(245,245,249,1);
  border-radius: 100%;
  background-color: #F5F5F9;
}

@media screen and (max-width: 991px) {
  .grid-2-col {
      grid-template-columns: auto;
  }
  .grid-3-col {
      grid-template-columns: auto;
  }
  .grid-4-col {
      grid-template-columns: auto;
  }
  .grid-5-col {
      grid-template-columns: 1fr 1fr;
  }
}

.nonTraditionalQuickPicContainer {
  padding: 5px;
  background-color: lightgrey;
}

.paymentProcessorButton {
  text-align: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #f0f0f0;
  background-color: #387dff;
}
.paymentProcessorButton:hover {
  background-color: #006da8;
}

.paymentProcessorButtonSelected {
  background-color: #387dff;
  color: #FFF;
}

.newNavBadge {
  position: relative;
  top: -1px;
  color: #fff;
  border-radius: 5px;
  padding: 2px;
  font-weight: bold;
  font-size: 0.6rem;
  margin-left: 5px;
  background-color: #387dff;
}

.login-wrapper {
  background-size: cover;
  height: 100vh;
  background-position: center;
}

.login-container {
  max-width: 350px;
  margin: 0 auto;
  padding: 1rem 1rem;
}

.login-logo {
  position: absolute;
  text-align: center;
  width: 100%;
  padding-top: 2rem;
}
.signup-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1rem 1rem;
}
.signup-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.5rem;
}

.downloadButton {
  display: inline-flex;
  color: #FFF;
  margin-right: 5px;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: all .15s ease-in-out;
}
.downloadButton > .downloadButtonVersion {
  font-weight: bold;
  margin-right: 10px;
}

.downloadButton > svg {
  font-size: 1.5rem;
}

.downloadButtonAndroid {
  background-color: #3DDC84;
}
.downloadButtonAndroid:hover {
  background-color: #36ca79;
}
.downloadButtoniOS {
  background-color: #000;
}
.downloadButtoniOS:hover {
  background-color: #2e2e2e;
}
.downloadButtonWindows {
  background-color: #00a2ed;
}
.downloadButtonWindows:hover {
  background-color: #33b8f5;
}
.dotted {
  border-radius: 10px;
  border: 1px dashed #bababa;
  margin-top: 5px;
  padding: 7px;
}
.versionNumber {
  margin-left: 9px;
}

.noStyleAnchor {
  text-decoration: none !important;
}

.text-decoration-none {
  color: inherit;
}

.text-decoration-none:hover {
  color: var(--dark);

}

.click-to-view {
  opacity: 0;
  transition: all 0.3s;
}

.grid-tech-stat:hover .click-to-view {
  opacity: 0.5;
}

.tk-top-bar {
  background-color: #404040;
  box-shadow: inset -1px 0px 0 0 #212121, -1px -1px 0 0 #212121, 0 2px 0 0 #404040;
}


.accordion-item {
  border: 1px solid#ced0d3;
  background-color: #fff;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;
  margin-bottom: 1rem;
}

.accordion-heading {
  position: relative;
  z-index: 999;
  border: 1px solid transparent;
  background-color: #fff;
  border-radius: 6px;
  padding: 1rem;
  font-weight: 600;
  font-size: 14px;
  transition: all 0.3s;
  cursor: pointer;
}

.accordion-heading:hover {
  box-shadow: inset 0 0 0 1px var(--primary);
  border-color: var(--primary);
}

.accordion-body {
  padding: 1rem;
  border-top: 1px solid#dfe3e8;
  background-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  cursor:default;
}

.tk-edit-bar-wrapper {
  position: relative;
  height: 100vh;
  overflow: scroll;
  background-color: var(--light);
  border-right: 2px solid #d6d8db;
  padding: 1rem 8px;
}

.tk-edit-bar {
  position: fixed;
}

.__react_component_tooltip {
  z-index: 9999 !important;
}

.company-color {
  padding: 1rem;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #d6d8db;
  margin-right: 1rem;
}

input[type="switch"] {
  position: relative;
  top: 1px;
  margin: 0 10px 0 0;
}

.rssKeyTokenCard {
  overflow: hidden;
}

.rssKeyTokenCardBody {
  text-align: center;
}

.rssKeyTokenCardBody button {
  margin: 5px;
}

.analyticsBadge {
  text-align: center;
}
  .analyticsBadge h2 {
    font-size: 20pt;
    margin-bottom: 0;
  }
  .analyticsBadge span {
    color: #6e6e6e;
  }

  .login-alert {
    border: 1px solid var(--warning);
    border-radius: 6px;
    background-color: var(--warning-light);
    padding: 0.75rem;
    color: var(warning);
    margin-top: 1rem;
  }